// adds class to body so we can enable/disable focus rings
// https://github.com/ten1seven/what-input
import "what-input";

import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/object-fit/ls.object-fit";
import 'lazysizes/plugins/native-loading/ls.native-loading';
import "lazysizes";

// Create a store for alpine to hold some customer club data
import Spruce from '@ryangjchandler/spruce';
Spruce.store('customer', {
  clubMemberships: [],
  hasActiveClub: false
})
import 'alpinejs';

// Rellax handles all parallax scrolling
import Rellax from 'rellax'
const rellax = new Rellax('.rellax', {
  breakpoints: [640, 768, 1024],
  center: true
});
// Refresh rellax whenever images are loaded
document.addEventListener('lazyloaded', function(e){
  if (rellax.hasOwnProperty('refresh')) {
    rellax.refresh()
  }
});

// Slider
// https://swiperjs.com/api/
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);
const swiper = new Swiper('.swiper-container', {
  spaceBetween: 32,
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return `<span aria-label="Slide Number ${index}" class="${className} rounded-none bg-white opacity-50 h-1 flex-1">&nbsp;</span>`;
    },
    bulletActiveClass: 'opacity-100'
  },
  navigation: {
    nextEl: '.slider-button-next',
    prevEl: '.slider-button-prev',
  },
});

// Using this to fix content winding up wider than the slide
// I think it has to do with images but unsure
document.addEventListener('lazyloaded', function(e){
  if (swiper.slides) {
    swiper.update()
  }
})